import { FieldError } from 'react-hook-form'

export const formatErrors = (fieldErrors?: FieldError | FieldError[]) => {
  if (!fieldErrors) {
    return ''
  }

  if (Array.isArray(fieldErrors)) {
    return fieldErrors.map(error => error.message).join(', ')
  }

  return fieldErrors.message
}
