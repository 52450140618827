import React, { useCallback, useEffect, useRef } from 'react'
import { eventTrackForm, sanitizeTrackId } from 'lib/gtm'
import { TrackFormType } from 'lib/types/gtm'

type TrackFormProps = React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
> & {
  id?: string
  children?: any
}

// This is a GTM helper <form> replacement component that wraps children and notifies GTM when form is loaded and updated
const TrackForm = ({ id, children, ...rest }: TrackFormProps) => {
  const curRef = useRef(null)
  const handleFormEvent = useCallback(
    (type: TrackFormType) => {
      if (!!type && !!id) {
        eventTrackForm(type, id)
      }
    },
    [id]
  )

  // Update GTM when form is initially loaded
  useEffect(() => {
    handleFormEvent(TrackFormType.INITIAL)
  }, [id])

  // Update GTM whenever form state is changed (eg. fields shown/toggled)
  const onElementMutation = useCallback(() => {
    handleFormEvent(TrackFormType.UPDATE)
  }, [])

  useEffect(() => {
    const obs = new MutationObserver(onElementMutation)

    if (curRef.current) {
      obs.observe(curRef.current, {
        childList: true,
        subtree: true,
      })
    }

    return () => {
      if (obs) {
        obs.disconnect()
      }
    }
  }, [])

  const formName = sanitizeTrackId(id || '')

  return (
    <form
      ref={curRef}
      name={formName}
      title={formName}
      {...rest}
    >
      {children}
    </form>
  )
}

TrackForm.displayName = 'TrackForm'

export default TrackForm
