import React from 'react'

export default function Layout({
  children,
  'data-test-id': testId,
}: {
  children: React.ReactNode
  'data-test-id'?: string
}) {
  return (
    <div className="anime" data-test-id={testId}>
      {children}
    </div>
  )
}
