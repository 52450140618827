export const DIALOG_CONTENTS = {
  // Confirmation
  getConfirmDeletePost: (unPublishOnly: boolean) => ({
    title: 'Delete post',
    content: [
      `Are you sure you want to ${
        unPublishOnly ? 'unpublish' : 'delete'
      } your post?`,
    ],
    'data-test-id': 'confirm-delete-post-dialog',
  }),
  getConfirmDuplicatePost: () => ({
    title: 'Duplicate post',
    content: [
      'This will copy the contents of this post to a new post, continue?',
    ],
    'data-test-id': 'confirm-duplicate-post-dialog',
  }),
  CONFIRM_CHANGE_COLOUR: {
    title: 'Change colour',
    content: [
      `Changing this colour will affect all your storefronts, are you sure?`,
    ],
    'data-test-id': 'confirm-change-colour-dialog',
  },
  CONFIRM_DELETE_IMAGE: {
    title: 'Delete image',
    content: ['Are you sure you want to delete this image?'],
    'data-test-id': 'confirm-delete-image-dialog',
  },
  CONFIRM_CHANGE_POST_TYPE: {
    title: 'Change post type',
    content: [
      `Changing the post type can result in the loss of some data that was specific to the previous post type, are you sure?`,
    ],
    'data-test-id': 'confirm-change-post-type-dialog',
  },
  CONFIRM_UPDATE_USER_ACCESS: {
    title: 'Update user access',
    content: [
      'The user will need to logout and log back in to see the changes',
    ],
    'data-test-id': 'confirm-user-access',
  },
  CONFIRM_DELETE_USER: {
    title: 'Delete user',
    content: ['Are you sure you want to delete this user?'],
    'data-test-id': 'delete-user',
  },
  FEEDBACK_VIDEOUPLOAD_INTRO: {
    title: 'We are now accepting video content for use on social media!',
    content: [
      'You can now submit video content for stand-alone social media, or add video content to your offer, news and events posts, to be considered for inclusion on our Westfield social media channels.',
    ],
    imageSrc: 'campaign-welcome.png',
    'data-test-id': 'feedback-videoupload-intro-dialog',
  },
  // Errors
  getImageDimensionsError: (
    width: number,
    height: number,
    minWidth: number,
    minHeight: number,
    name?: string
  ) => ({
    title: 'Image dimensions',
    content: [
      `The image${
        name && ` (${name})`
      } you have uploaded is ${width}x${height}, our minimum required size is ${minWidth}x${minHeight}. Please upload an image that meets these requirements`,
    ],
    'data-test-id': 'image-dimensions-error-dialog',
  }),
  getMediaFileSizeError: (
    type: 'image' | 'video',
    size: string,
    maxSize: number,
    name?: string
  ) => ({
    title: `${type} size`,
    content: [
      `The ${type}${
        name && ` (${name})`
      } you have uploaded is ${size} MB, our maximum limit for ${type} size is ${maxSize} MB. Please upload ${
        type === 'image' ? `an ${type}` : `a ${type}`
      } that meets these requirements`,
    ],
    'data-test-id': 'media-size-error-dialog',
  }),
  IMAGE_UPLOAD_ERROR: {
    title: 'Image upload',
    content: ['Your image failed to upload'],
    'data-test-id': 'image-upload-error-dialog',
  },
  MEDIA_UPLOAD_ERROR: {
    title: 'Media upload',
    content: ['Some of your media items failed to upload. Please try again'],
    'data-test-id': 'media-upload-error-dialog',
  },
  MAXIMUM_SUPPORTING_IMAGES_ERROR: {
    title: 'Image maximum',
    content: ['Sorry, we can only take 9 supporting images'],
    'data-test-id': 'maximum-supporting-images-error-dialog',
  },
  MAXIMUM_SUPPORTING_MEDIA_ERROR: {
    title: 'Media maximum',
    content: ['Sorry, we can only take 9 supporting media'],
    'data-test-id': 'maximum-supporting-media-error-dialog',
  },
  IMAGE_CROP_ERROR: {
    title: 'Image crop',
    content: ['Image cropping failed.'],
    'data-test-id': 'image-crop-error-dialog',
  },
  LEAVING_PAGEING_CONFIRM: {
    title: 'You have unsaved changes to this page',
    content: [
      'Are you sure you want to leave this page? Any changes you made will be cancelled',
    ],
    customCancelLabel: 'Cancel',
    customConfirmLabel: 'Leave Page',
    'data-test-id': 'leave-page-confirm-dialog',
  },
  getRetailerRemovalConfirm: (retailerName: string) => ({
    title: 'Remove retailer',
    content: [`Are you sure you want to remove ${retailerName}?`],
    customCancelLabel: 'Cancel',
    customConfirmLabel: 'Remove',
    'data-test-id': 'remove-retailer-confirm-dialog',
  }),
}
