import { Theme } from '@mui/material'
import { SxProps } from '@mui/system'
import React, {
  useState,
  useEffect,
  RefObject,
  forwardRef,
  HTMLInputTypeAttribute,
} from 'react'
import MUITextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import useStyles from './styles'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'

const DEFAULT_ROWS = 4

type Props = {
  id?: string
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined
  name: string
  maxLength?: number
  multiline?: boolean
  minRows?: number
  label: string
  helperText?: string | JSX.Element
  alwaysShowHelperText?: boolean
  fullWidth?: boolean
  required?: boolean
  prefix?: string
  defaultValue?: string
  withPadding?: boolean
  disabled?: boolean
  maxRows?: number
  placeholder?: string
  'data-test-id'?: string
  inputRef?: RefObject<Element | null>
  value: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: HTMLInputTypeAttribute
  sx?: SxProps<Theme>
}

const TextField = forwardRef<HTMLInputElement, Props>(function TextField(
  {
    name,
    'data-test-id': testId = 'text-field',
    maxLength,
    multiline = false,
    minRows = DEFAULT_ROWS,
    label,
    disabled,
    helperText,
    alwaysShowHelperText = false,
    fullWidth = true,
    required = false,
    prefix,
    withPadding = true,
    maxRows,
    placeholder,
    inputRef,
    error,
    onChange,
    ...rest
  },
  ref
) {
  const classes = useStyles()
  const [isFocused, setIsFocused] = useState(false)
  const { value } = rest
  useEffect(() => {
    const handleBlur = () => setIsFocused(false)
    inputRef?.current?.addEventListener('blur', handleBlur)
    return () => {
      inputRef?.current?.removeEventListener('blur', handleBlur)
    }
  }, [inputRef])

  return (
    <MUITextField
      {...rest}
      value={value || ''}
      name={name}
      inputRef={inputRef}
      required={required}
      fullWidth={fullWidth}
      multiline={multiline}
      disabled={disabled}
      placeholder={placeholder}
      maxRows={maxRows}
      {...(multiline && { minRows })}
      color="primary"
      variant="outlined"
      label={label}
      error={Boolean(error)}
      classes={withPadding ? { root: classes.root } : {}}
      helperText={
        error || (helperText && (isFocused || alwaysShowHelperText)) ? (
          <span className={classes.helperTextContainer}>
            <span className={classes.helperTextLeft}>
              {error ? (
                <span data-test-id={`${testId}-error-message`}>
                  {error as string}
                </span>
              ) : (
                <span data-test-id={`${testId}-helper-text-left`}>
                  {helperText}
                </span>
              )}
            </span>
            <span>
              {maxLength && (
                <span data-test-id={`${testId}-helper-text-right`}>{`${
                  value?.length || 0
                }/${maxLength - (prefix?.length || 0)}`}</span>
              )}
            </span>
          </span>
        ) : null
      }
      inputProps={{
        'data-test-id': testId,
        ...(maxLength && { maxLength: maxLength - (prefix?.length || 0) }),
      }}
      InputProps={{
        startAdornment: prefix && (
          <div className={classes.prefix}>{`${prefix}`}</div>
        ),
        endAdornment: error && (
          <InputAdornment position="start">
            <ErrorOutlineIcon color="error" />
          </InputAdornment>
        ),
      }}
      ref={ref}
      onFocus={() => setIsFocused(true)}
      onChange={onChange}
    />
  )
})

export default TextField
