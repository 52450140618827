import { makeStyles } from '@mui/styles'

export default makeStyles({
  root: {
    paddingBottom: '24px',
  },
  prefix: {
    whiteSpace: 'nowrap',
    paddingRight: '4px',
    color: 'rgb(131,131,131)',
    fontSize: '16px',
    fontWeight: 'normal',
  },
  helperTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  helperTextLeft: {
    display: 'flex',
    width: '90%',
  },
})
