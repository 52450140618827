import React, { useEffect, useState, forwardRef, useRef } from 'react'
import Grid from '@mui/material/Grid'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import useStyles from './styles'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { CSSFlexDirection } from 'lib/types/styles'
import TrackBox from 'components/Track/TrackBox'
import { sanitizeId } from 'lib/utils'

type Props = {
  options: { label: string; value: string }[]
  handleChange?: () => void
  error?: string
  defaultValues?: string[]
  tooltipText?: string | null
  orientation?: CSSFlexDirection
  values: string[]
  onChange: (arg: string[]) => void
  disabled?: boolean
  'data-test-id'?: string
}

const CheckboxGroup = forwardRef<HTMLInputElement, Props>(
  function CheckboxGroup(
    {
      options,
      handleChange = () => null,
      error,
      defaultValues = [],
      tooltipText = null,
      orientation = 'row',
      values,
      onChange,
      'data-test-id': testId,
      disabled = false,
      ...rest
    },
    ref
  ) {
    const classes = useStyles({ orientation })
    const [checkedValues, setCheckedValues] = useState(defaultValues)
    const defaultValuesRef = useRef(defaultValues)

    function handleSelect(checkedOption: string) {
      const newOptions = checkedValues?.includes(checkedOption)
        ? checkedValues?.filter(option => option !== checkedOption)
        : [...(checkedValues ?? []), checkedOption]
      setCheckedValues(newOptions)
      return newOptions
    }

    useEffect(() => {
      setCheckedValues(values ?? defaultValuesRef.current)
    }, [values, defaultValuesRef])

    function handleErrorMessage() {
      if (error) {
        return (
          <span
            className={classes.error}
            data-test-id={`${testId}-error-message`}
          >
            <span>{error}</span>
          </span>
        )
      }
      return null
    }

    return (
      <Grid container className={classes.container} data-test-id={testId}>
        {options.map(option => (
          <TrackBox id={option.label} transparent={true} key={option.value}>
            <FormControlLabel
              {...rest}
              className={classes.checkboxRoot}
              label={
                <>
                  {option.label}
                  {tooltipText && <Tooltip title={tooltipText} />}
                </>
              }
              data-test-id={`${sanitizeId(option.label)}-checkbox-label`}
              control={
                <Checkbox
                  color="secondary"
                  checked={checkedValues.includes(option.value)}
                  id={option.value}
                  data-test-id={sanitizeId(option.value)}
                  onChange={() => {
                    onChange(handleSelect(option.value))
                    handleChange()
                  }}
                  disabled={disabled}
                />
              }
              ref={ref}
            />
          </TrackBox>
        ))}

        <FormHelperText component={handleErrorMessage} />
      </Grid>
    )
  }
)

export default CheckboxGroup
