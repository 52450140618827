import { makeStyles } from '@mui/styles'

const STYLE_RADIUS = 3
const PADDED_BUTTON_VERTICAL = 1
const PADDED_BUTTON_HORIZONTAL = 3
const SMALL_BUTTON = 2
const SMALL_BUTTON_HEIGHT = 2.5

export default makeStyles(theme => ({
  root: (props: {
    shape?: string | null
    padded?: boolean | null
    color?: string | null
    variant?: string | null
    size?: string | null
  }) => ({
    borderRadius: props.shape === 'round' ? '100px' : STYLE_RADIUS,
    padding: props.padded
      ? `${theme.spacing(PADDED_BUTTON_VERTICAL)} ${theme.spacing(
          PADDED_BUTTON_HORIZONTAL
        )}`
      : '0',
    color:
      props.variant === 'outlined'
        ? theme.palette.primary.dark
        : props.color || theme.palette.common.white,
    ...(props?.size === 'small' && {
      '& span.MuiButton-label': {
        height: theme.spacing(SMALL_BUTTON_HEIGHT),
        padding: `0 ${theme.spacing(SMALL_BUTTON)}`,
      },
    }),
  }),
  loadingContentWrapper: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
}))
