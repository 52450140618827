import React, { forwardRef } from 'react'
import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import useStyles from './styles'

type ButtonProps = Omit<MUIButtonProps, 'type'> & {
  label?: string
  color?: 'primary' | 'secondary'
  loading?: boolean
  loadingText?: string
  disabled?: boolean
  variant?: 'contained' | 'text' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  startIcon?: JSX.Element | null
  endIcon?: JSX.Element | null
  textColor?: string | null
  type?: 'button' | 'reset' | 'submit'
  shape?: 'round' | 'square'
  padded?: boolean
  fullWidth?: boolean
  style?: React.CSSProperties
  'data-test-id'?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    label,
    color,
    loading = false,
    loadingText,
    disabled = false,
    variant = 'contained',
    startIcon = null,
    endIcon = null,
    textColor = null,
    type,
    shape = 'square',
    padded = true,
    fullWidth = true,
    size,
    'data-test-id': testId = 'button',
    style,
    ...remainingButtonProps
  },
  ref
) {
  const classes = useStyles({
    color: textColor,
    shape,
    padded,
    variant,
    size,
  })

  return (
    <MUIButton
      {...remainingButtonProps}
      fullWidth={fullWidth}
      data-test-id={testId}
      color={color}
      className={classes.root}
      variant={variant}
      disabled={loading || disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      ref={ref}
      style={style}
      type={type}
    >
      {loading ? (
        <div className={classes.loadingContentWrapper}>
          <CircularProgress size={24} /> {loadingText}
        </div>
      ) : (
        label
      )}
    </MUIButton>
  )
})

export default Button
